<template>
  <page-layout ref="layout">

    <template #actions="{ }">
      <can do="create" on="management-ensembles">
        <b-button v-b-tooltip="'Create'" v-b-modal="'ensemble-modal'" variant="transparent" size="sm">
          <font-awesome-icon icon="fa-solid fa-plus" />
        </b-button>
        <ensemble-modal id="ensemble-modal" @created="addEnsemble" />
      </can>
    </template>

    <template #dropdown-options="{ }">
      <b-dropdown-item @click="refresh">
        <feather-icon icon="RotateCwIcon"/>
        <span class="align-middle ml-50">Refresh</span>
      </b-dropdown-item>
    </template>

    <template #content="{ state }">
      <table-layout ref="table-layout"
                    :items="table.items"
                    :fields="table.fields"
                    :sorting="table.sorting"
                    :loading="table.loading"
                    :subscriptions="table.subscriptions"
                    :is-searchable="true"
                    :func-delete="deleteEnsemble"
                    :export-exclude-fields="[
                        'id',
                        'slug',
                        'state.enabled',
                    ]"
                    @mounted="table = $event"
                    @updated="table = $event">

        <template #overlay>
          <overlay-loading :items="[
            { state: table.loading, desc: 'Loading Ensembles', loaded: table.items.length},
            { state: state.loading, desc: 'Rendering Template'},
          ]" />
        </template>

        <!-- Column: Name -->
        <template #cell(name)="{data}">
          <b-media vertical-align="center" no-body>
            <b-media-aside>
              <b-avatar v-if="hasCustomAvatar(data.item.avatar)" v-b-modal.avatar-modal variant="primary" size="2.5em" badge-variant="white">
                <b-img fluid fluid-grow :src="data.item.avatar.src" :alt="data.item.avatar.name"></b-img>
              </b-avatar>
              <b-avatar v-else v-b-modal.avatar-modal variant="primary" size="2.5em" badge-variant="white">
                <font-awesome-icon :icon="icon"></font-awesome-icon>
              </b-avatar>
            </b-media-aside>
            <b-media-body class="align-self-center">
              <b-link :to="{ name: 'management-ensemble', params: { id: data.item.id, slug: data.item.slug } }" class="font-weight-bold d-block text-nowrap">
                {{ data.item.name }}
              </b-link>
            </b-media-body>
          </b-media>
        </template>

        <!-- Column: State - Enabled -->
        <template #cell(state.enabled)="{data}">
          <b-badge pill :variant="`light-${data.item.state.enabled ? 'success' : 'danger'}`" class="text-capitalize">
            {{ data.item.state.enabled }}
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template #cell(row-options)="{data}">
          <b-dropdown-item :to="{ name: 'management-ensemble', params: { id: data.item.id, slug: data.item.slug } }"
                           class="table-row-option-view">
            <feather-icon icon="FileTextIcon" />
            <span class="align-middle ml-50">View</span>
          </b-dropdown-item>

          <template v-if="$can('update', 'management-ensembles') || $can('delete', 'management-ensembles')">
            <b-dropdown-divider />
            <can do="update" on="management-ensembles">
              <b-dropdown-item class="table-row-option-enable"
                               @click="updateEnsemble(data.item)">
                <font-awesome-icon :icon="['fas', data.item.state.enabled ? 'toggle-off' : 'toggle-on']"/>
                <span class="align-middle ml-50">{{ data.item.state.enabled ? 'Disable' : 'Enable' }}</span>
              </b-dropdown-item>
            </can>
            <can do="delete" on="management-ensembles">
              <b-dropdown-item class="table-row-option-delete"
                               @click="$refs.layout.confirmDelete(data.item, deleteEnsemble, cascadeConfirmDeleteOptions)">
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">Delete</span>
              </b-dropdown-item>
            </can>
          </template>
        </template>
      </table-layout>
    </template>

    <template #debug>
      <debug :collapsed="true">{{ table }}</debug>
    </template>
  </page-layout>
</template>

<script>
import PageLayout from '@/components/PageLayout.vue';
import TableLayout from '@/components/TableLayout.vue';
import OverlayLoading from '@/components/OverlayLoading.vue';
import EnsembleModal from '@/views/management/ensembles/EnsembleModal.vue'
import avatar from '@/mixins/avatar.mixin';
import notify from '@/mixins/notify.mixin';
import print from '@/mixins/print.mixin';
import { API, graphqlOperation} from 'aws-amplify';
import {
  listEnsembles, listEnsembleInstrumentsForDeletion, listSelectionsForDeletion,
  updateEnsemble, updateStudent,
  deleteEnsemble, deleteEnsembleInstruments, deleteSelection, deleteBill,
  onCreateEnsemble, onUpdateEnsemble, onDeleteEnsemble
} from '@/graphql/queries/ensembles';
import { cascadeDeleteEnsemble, cascadeConfirmDeleteOptions} from '@/graphql/cascade/ensemble';

export default {
  name: 'Ensembles',
  components: {
    PageLayout,
    TableLayout,
    OverlayLoading,
    EnsembleModal
  },
  mixins: [ avatar, notify, print ],
  data() {
    return {
      table: {
        items: [],
        loading: true,
        fields: [
          {
            key: 'name',
            label: 'Name',
            sortable: true,
            filterable: true,
            visible: true,
            tdClass: 'align-middle'
          },
          {
            key: 'code',
            label: 'Code',
            sortable: true,
            filterable: true,
            visible: true,
            tdClass: 'align-middle'
          },
          {
            key: 'state.enabled',
            label: 'Enabled',
            sortable: true,
            filterable: true,
            visible: true,
            tdClass: 'align-middle'
          },
          {
            key: 'row-options',
            label: '',
            sortable: false,
            visible: true,
            tdClass: ['align-middle', 'table-row-options']
          },
        ],
        sorting: {
          by: 'name',
          desc: false
        },
        subscriptions: {
          onCreate: null,
          onUpdate: null,
          onDelete: null
        }
      },
      icon: 'fa-solid fa-people-line',
      debug: { },
      cascadeConfirmDeleteOptions
    }
  },
  async created() {
    await this.listEnsembles();
    this.onCreateEnsemble();
    this.onUpdateEnsemble();
    this.onDeleteEnsemble();
  },
  mounted() {
    this.$refs.layout.state.loading = false
  },
  methods: {
    /** Table Data **/
    async listEnsembles() {
      this.table.loading = true
      const response = await API.graphql(graphqlOperation(listEnsembles));
      this.table.items = response.data.listEnsembles.items;
      await this.$nextTick(() => {
        setTimeout( () => { this.table.loading = false }, 500);
      })
    },
    async addEnsemble(ensemble) {
      this.table.loading = true
      this.table.items.push(ensemble)
      this.table.loading = false
    },
    async updateEnsemble(ensemble) {
      try {
        ensemble.state.enabled = !ensemble.state.enabled
        await API.graphql(graphqlOperation(updateEnsemble, { input: { id: ensemble.id, state: ensemble.state } } ));
        this.notify({ title: 'Success', text: 'Ensemble was successfully updated', icon: this.icon, variant: 'success' });
      }
      catch (error) {
        console.error(error)
        this.notify({ title: 'Error', text: 'Ensemble failed to update', icon: this.icon, variant: 'danger'});
      }
    },
    async deleteEnsemble(ensemble, swalCallback) {
      try {
        this.table.loading = true
        await this.cascadeDeleteEnsemble(ensemble.id, swalCallback)
        this.table.items = this.table.items.filter(item => item.id !== ensemble.id);
        this.notify({ title: 'Success', text: 'Ensemble was successfully deleted', icon: this.icon, variant: 'success' });
      }
      catch(error) {
        console.error(error)
        this.notify({ title: 'Error', text: 'Ensemble failed to delete', icon: this.icon, variant: 'danger'});
        throw error //for Swal
      }
      finally {
        this.table.loading = false
      }
    },
    cascadeDeleteEnsemble,

    /** Subscriptions **/
    onCreateEnsemble() {
      this.table.subscriptions.onCreate = API.graphql(graphqlOperation(onCreateEnsemble)).subscribe((sourceData) => {
        this.syncNotification()
        const ensemble = sourceData.value.data.onCreateEnsemble
        if (ensemble && !this.table.items.map(item => item.id).includes(ensemble.id)) {
          this.table.loading = true
          this.table.items = [ensemble, ...this.table.items];
          this.table.loading = false
        }
      });
    },
    onUpdateEnsemble() {
      this.table.subscriptions.onUpdate = API.graphql(graphqlOperation(onUpdateEnsemble)).subscribe((sourceData) => {
        this.syncNotification()
        const ensemble = sourceData.value.data.onUpdateEnsemble
        if (ensemble && this.table.items.map(item => item.id).includes(ensemble.id)) {
          this.table.loading = true
          const index = this.table.items.findIndex(item => item.id === ensemble.id)
          if(index > -1) {
            this.table.items.splice(index, 1, ensemble)
          }
          this.table.loading = false
        }
      });
    },
    onDeleteEnsemble() {
      this.table.subscriptions.onDelete = API.graphql(graphqlOperation(onDeleteEnsemble)).subscribe((sourceData) => {
        this.syncNotification()
        const ensemble = sourceData.value.data.onDeleteEnsemble
        if(ensemble && this.table.items.map(item => item.id).includes(ensemble.id)) {
          this.table.loading = true
          this.table.items = this.table.items.filter(item => item.id !== ensemble.id);
          this.table.loading = false
        }
      });
    },

    /** Util **/
    async refresh() {
      await this.listEnsembles()
    },
  }
}

</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-select.scss';

.table-actions {
  width: 33px;
  text-align: center;
}
.tbody-tr-border {
  border-bottom: 1px solid #e9ecef;
}
.tbody-tr-border:last-child { border-bottom: none; }

</style>
