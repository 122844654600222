// eslint-disable-next-line import/prefer-default-export
export const createEnsemble = /* GraphQL */ `
    mutation CreateEnsemble(
        $input: CreateEnsembleInput!
        $condition: ModelEnsembleConditionInput
    ) {
        createEnsemble(input: $input, condition: $condition) {
            id
            slug
            name
            code
            state {
                enabled
            }
            createdAt
            updatedAt
        }
    }
`;


