<template>
  <b-modal :id="id" centered size="xl" title="New Ensemble" no-close-on-backdrop footer-class="d-block" @hidden="clear()">
    <!-- Content -->
    <template #default>
      <b-container>

        <validation-observer ref="observer" v-slot="{ handleSubmit }" tag="div">
          <form ref="form" @submit.stop.prevent="handleSubmit(create)">
            <b-row>
              <b-col cols="8">
                <validation-provider v-slot="validationContext" vid="name" name="Name" rules="required">
                  <b-form-group label="Name" label-for="name-input" :invalid-feedback="validationContext.errors[0]">
                    <b-form-input id="name-input" v-model="ensemble.name" :state="getValidationState(validationContext)"></b-form-input>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="4">
                <validation-provider v-slot="validationContext" vid="code" name="Code" rules="required">
                  <b-form-group label="Code" label-for="name-input" :invalid-feedback="validationContext.errors[0]">
                    <b-form-input id="code-input" v-model="ensemble.code" :state="getValidationState(validationContext)"></b-form-input>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12">
                <b-form inline class="my-1">
                  <b-form-checkbox v-model="ensemble.hasParts" switch class="mr-1" >
                    Has Parts?
                    <span class="sr-only">Switch for following text input</span>
                  </b-form-checkbox>

                  <b-form-checkbox v-model="ensemble.hasAlternates" switch>
                    Has Alternates?
                    <span class="sr-only">Switch for following text input</span>
                  </b-form-checkbox>
                </b-form>

                <validation-provider v-slot="validationContext" vid="parts" name="Parts" rules="numeric">
                  <b-form-group label-for="parts" class="mb-1">
                    <b-input-group :class="[getValidationClass(validationContext), !ensemble.hasParts ? 'disabled' : null]">
                      <b-input-group-prepend is-text>
                        <small>Parts</small>
                      </b-input-group-prepend>
                      <b-input id="parts" v-model.number="ensemble.parts" type="number" :disabled="!ensemble.hasParts" :state="getValidationState(validationContext)"></b-input>
                    </b-input-group>
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>


              </b-col>
            </b-row>
          </form>
        </validation-observer>
      </b-container>
    </template>

    <!-- Footer -->
    <template #modal-footer>
      <b-row>
        <b-col align-self="center">
          <b-checkbox v-model="state.redirect" :checked="true" :unchecked-value="false">
            <small>Redirect to page on submit</small>
          </b-checkbox>
        </b-col>
        <b-col align-self="center" class="text-right">
          <b-button type="reset" class="mr-1" @click="clear()">Clear</b-button>
          <b-button type="submit" variant="primary" @click="$refs.observer.handleSubmit(create)">Submit</b-button>
        </b-col>
      </b-row>
    </template>
  </b-modal>
</template>

<script>
import { API, graphqlOperation } from 'aws-amplify';
import { uuid } from 'vue-uuid';
import vSelect from 'vue-select'
import notify from '@/mixins/notify.mixin'
import { createEnsemble } from '@/graphql/queries/ensemble-modal';
import slugify from 'slugify';

export default {
  name: 'EnsembleModal',
  components: {
    vSelect
  },
  mixins: [ notify ],
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      ensemble: {
        name: null,
        code: null,
        parts: null,
        hasParts: false,
        hasAlternates: false
      },
      state: {
        redirect: false
      },
    }
  },
  methods: {
    async create() {
      try {
        const id = uuid.v4();
        const slug = slugify(this.ensemble.name, { lower: true })
        const ensembleInput = {
          id: id,
          slug: slug,
          name: this.ensemble.name,
          code: this.ensemble.code || 'X',
          parts: this.ensemble.parts,
          hasParts: this.ensemble.hasParts,
          hasAlternates: this.ensemble.hasAlternates,
          state: {
            enabled: true
          }
        }

        const response = await API.graphql(graphqlOperation(createEnsemble, { input: ensembleInput }));
        const ensemble = response.data.createEnsemble

        if(this.state.redirect) {
          await this.$router.push({name: 'management-ensemble', params: { id: id, slug: slug }})
        }
        else {
          this.$emit('created', ensemble)
          this.$root.$emit('bv::toggle::modal', this.id)
          this.notify({ title: 'Success', text: 'Ensemble was successfully created', icon: 'fas fa-server', variant: 'success' });
        }
      }
      catch (error) {
        console.error(error)
        this.notify({ title: 'Error', text: 'Ensemble failed to create', icon: 'fas fa-server', variant: 'danger'});
      }
    },
    clear() {
      this.ensemble = {
        name: null,
        parts: null,
        hasParts: false,
        hasAlternates: false
      }
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    getValidationClass(validationContext) {
      const state = this.getValidationState(validationContext)
      if(state === null) return null
      return state ? 'is-valid' : 'is-invalid'
    },
  }
}
</script>

<style scoped>

</style>
