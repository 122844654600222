export const listEnsembles = /* GraphQL */ `
    query ListEnsembles(
        $id: ID
        $filter: ModelEnsembleFilterInput
        $limit: Int
        $nextToken: String
        $sortDirection: ModelSortDirection
    ) {
        listEnsembles(
            id: $id
            filter: $filter
            limit: $limit
            nextToken: $nextToken
            sortDirection: $sortDirection
        ) {
            items {
                id
                slug
                name
                code
                state {
                    enabled
                }
                createdAt
                updatedAt
            }
            nextToken
        }
    }
`;
export const listEnsembleInstrumentsForDeletion = /* GraphQL */ `
    query ListEnsembleInstruments(
        $filter: ModelEnsembleInstrumentsFilterInput
        $limit: Int
        $nextToken: String
    ) {
        listEnsembleInstruments(
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
            }
            nextToken
        }
    }
`;
export const listSelectionsForDeletion = /* GraphQL */ `
    query ListSelections(
        $ensembleId: ID
        $limit: Int
        $nextToken: String
    ) {
        listSelections(
            filter: {selectionEnsembleId: { eq: $ensembleId }}
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
                selectionEnsembleId
                application {
                    student {
                        id
                        studentBillId
                        applications {
                            items {
                                id
                                selections(filter: {selectionEnsembleId: {ne: $ensembleId}}) {
                                    items {
                                        id
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const updateEnsemble = /* GraphQL */ `
    mutation UpdateEnsemble(
        $input: UpdateEnsembleInput!
        $condition: ModelEnsembleConditionInput
    ) {
        updateEnsemble(input: $input, condition: $condition) {
            id
            slug
            name
            code
            state {
                enabled
            }
            createdAt
            updatedAt
        }
    }
`;
export const updateStudent = /* GraphQL */ `
    mutation UpdateStudent(
        $input: UpdateStudentInput!
        $condition: ModelStudentConditionInput
    ) {
        updateStudent(input: $input, condition: $condition) {
            id
            name {
                first
                last
            }
            dob
            sex
            gender
            address {
                line1
                line2
                city
                county
                state
                zip
            }
            phone {
                type
                number
                ext
            }
            email {
                type
                address
            }
            grade
            school {
                id
                beds
                slug
                name {
                    legal
                    popular
                }
                avatar {
                    size
                    name
                    src
                }
                address {
                    line1
                    line2
                    city
                    county
                    state
                    zip
                }
                zone {
                    id
                    name
                    counties
                    createdAt
                    updatedAt
                }
                district {
                    id
                    beds
                    slug
                    createdAt
                    updatedAt
                    districtZoneId
                }
                staff {
                    type
                    title
                }
                users {
                    nextToken
                }
                state {
                    enabled
                }
                createdAt
                updatedAt
                districtSchoolsId
                schoolZoneId
            }
            applications {
                items {
                    id
                    recommendation
                    comments
                    createdAt
                    updatedAt
                    studentApplicationsId
                    applicationFestivalId
                    applicationFormId
                    applicationInstrumentId
                    teacherID
                    applicationSelectionId
                }
                nextToken
            }
            bill {
                id
                payments {
                    type
                    amount
                    check
                    received
                    returned
                }
                student {
                    id
                    dob
                    sex
                    gender
                    grade
                    createdAt
                    updatedAt
                    schoolID
                    studentBillId
                }
                memo
                duplicates
                createdAt
                updatedAt
                billStudentId
            }
            createdAt
            updatedAt
            schoolID
            studentBillId
        }
    }
`;

export const deleteEnsemble = /* GraphQL */ `
    mutation DeleteEnsemble(
        $input: DeleteEnsembleInput!
        $condition: ModelEnsembleConditionInput
    ) {
        deleteEnsemble(input: $input, condition: $condition) {
            id
            slug
            name
            code
            state {
                enabled
            }
            createdAt
            updatedAt
        }
    }
`;
export const deleteEnsembleInstruments = /* GraphQL */ `
    mutation DeleteEnsembleInstruments(
        $input: DeleteEnsembleInstrumentsInput!
        $condition: ModelEnsembleInstrumentsConditionInput
    ) {
        deleteEnsembleInstruments(input: $input, condition: $condition) {
            id
            ensembleID
            instrumentID
            ensemble {
                id
                slug
                name
                code
                parts
                instruments {
                    nextToken
                }
                hasParts
                hasAlternates
                state {
                    enabled
                }
                createdAt
                updatedAt
            }
            instrument {
                id
                slug
                name
                order
                form {
                    id
                    slug
                    name
                    type
                    createdAt
                    updatedAt
                }
                ensembles {
                    nextToken
                }
                state {
                    enabled
                }
                createdAt
                updatedAt
                formInstrumentsId
            }
            createdAt
            updatedAt
        }
    }
`;
export const deleteSelection = /* GraphQL */ `
    mutation DeleteSelection(
        $input: DeleteSelectionInput!
        $condition: ModelSelectionConditionInput
    ) {
        deleteSelection(input: $input, condition: $condition) {
            id
            part
            ranking {
                state
            }
            ensemble {
                id
                slug
                name
                code
                parts
                instruments {
                    nextToken
                }
                hasParts
                hasAlternates
                state {
                    enabled
                }
                createdAt
                updatedAt
            }
            accepted
            application {
                id
                festival {
                    id
                    slug
                    name
                    site
                    createdAt
                    updatedAt
                    festivalZoneId
                }
                form {
                    id
                    slug
                    name
                    type
                    createdAt
                    updatedAt
                }
                instrument {
                    id
                    slug
                    name
                    order
                    createdAt
                    updatedAt
                    formInstrumentsId
                }
                student {
                    id
                    dob
                    sex
                    gender
                    grade
                    createdAt
                    updatedAt
                    schoolID
                    studentBillId
                }
                teacher {
                    id
                    username
                    ensembleIds
                    instrumentIds
                    createdAt
                    updatedAt
                    districtUsersId
                }
                grading {
                    tone
                    rhythmicSense
                    leadership
                    maturity
                    articulation
                    intonation
                    citizenship
                    diction
                    flexibility
                    generalMusicianship
                    attitude
                    technique
                    sightReading
                    responsibility
                }
                recommendation
                comments
                ranking {
                    score
                    local
                    state
                }
                selections {
                    nextToken
                }
                selection {
                    id
                    part
                    accepted
                    createdAt
                    updatedAt
                    applicationSelectionsId
                    selectionEnsembleId
                }
                createdAt
                updatedAt
                studentApplicationsId
                applicationFestivalId
                applicationFormId
                applicationInstrumentId
                teacherID
                applicationSelectionId
            }
            state {
                order
            }
            createdAt
            updatedAt
            applicationSelectionsId
            selectionEnsembleId
        }
    }
`;
export const deleteBill = /* GraphQL */ `
    mutation DeleteBill(
        $input: DeleteBillInput!
        $condition: ModelBillConditionInput
    ) {
        deleteBill(input: $input, condition: $condition) {
            id
            payments {
                type
                amount
                check
                received
                returned
            }
            student {
                id
                name {
                    first
                    last
                }
                dob
                sex
                gender
                address {
                    line1
                    line2
                    city
                    county
                    state
                    zip
                }
                phone {
                    type
                    number
                    ext
                }
                email {
                    type
                    address
                }
                grade
                school {
                    id
                    beds
                    slug
                    createdAt
                    updatedAt
                    districtSchoolsId
                    schoolZoneId
                }
                applications {
                    nextToken
                }
                bill {
                    id
                    memo
                    duplicates
                    createdAt
                    updatedAt
                    billStudentId
                }
                createdAt
                updatedAt
                schoolID
                studentBillId
            }
            memo
            duplicates
            createdAt
            updatedAt
            billStudentId
        }
    }
`;


export const onCreateEnsemble = /* GraphQL */ `
    subscription OnCreateEnsemble {
        onCreateEnsemble {
            id
            slug
            name
            code
            state {
                enabled
            }
            createdAt
            updatedAt
        }
    }
`;
export const onUpdateEnsemble = /* GraphQL */ `
    subscription OnUpdateEnsemble {
        onUpdateEnsemble {
            id
            slug
            name
            code
            state {
                enabled
            }
            createdAt
            updatedAt
        }
    }
`;
export const onDeleteEnsemble = /* GraphQL */ `
    subscription OnDeleteEnsemble {
        onDeleteEnsemble {
            id
            slug
            name
            code
            state {
                enabled
            }
            createdAt
            updatedAt
        }
    }
`;

